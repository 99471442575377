import { Typography, Box } from '@mui/material';

const Policy = () => {
  return (
    <Box p={2} maxWidth={960} margin="0 auto">
      <Typography component="h1" variant="h4" textAlign="center" my={2}>
        ข้อตกลงการใช้งาน
      </Typography>
      <Typography component="p">
        www.codesass.com
        ให้บริการเผยแพร่ความรู้ด้านการเขียนโปรแกรมผ่านสื่อการสอนประเภทต่าง ๆ
        เช่นคอร์สเรียนและบทความ เราได้กำหนดข้อตกลงการใช้งานดังต่อไปนี้
      </Typography>
      <Typography variant="h5" my={2}>
        ข้อกำหนดด้านบัญชีผู้ใช้งาน
      </Typography>
      <Typography component="p">
        บัญชีผู้ใช้งานหมายถึง
        ข้อมูลตัวแทนที่ได้จากการสมัครสมาชิกและเข้าสู่ระบบของเว็บไซต์ (User
        Account) ที่สามารถระบุไปยังผู้ใช้งานที่แท้จริงได้
        โดยข้อมูลอื่นสามารถผูกความสัมพันธ์กับข้อมูลบัญชีผู้ใช้งานได้ เช่น
        ประวัติการสั่งซื้อสินค้าและบริการ เป็นต้น
        เรากำหนดเงื่อนไขการใช้งานเกี่ยวกับบัญชีผู้ใช้งานดังต่อไปนี้
        <ul>
          <li>
            ข้อมูลบัญชีผู้ใช้งานจะถูกใช้กับผลิตภัณฑ์ส่วนอื่นของเว็บไซต์ เช่น
            เราใช้ ชื่อ-นามสกุล ของคุณในการออกใบรับรองการผ่านหลักสูตร
            หรือใช้อีเมล์เพื่อประชาสัมพันธ์ผลิตภัณฑ์ของเราที่คุณน่าจะสนใจ
            เราจึงแนะนำให้คุณให้ข้อมูลที่ถูกต้องและอัพเดทข้อมูลเหล่านี้ให้เป็นปัจจุบันอยู่เสมอ
          </li>
          <li>
            ไม่อนุญาตให้เปิดเผย แสดงให้ปรากฎต่อบุคคลที่สาม
            หรือใช้งานบัญชีผู้ใช้งานร่วมกับผู้อื่น
            หากเราตรวจพบการกระทำดังกล่าวเราสามารถระงับการให้บริการบัญชีผู้ใช้งานของคุณได้ทันที
            ภายหลังการระงับการใช้งานบัญชีคุณจะไม่สามารถเข้าถึงสินค้าและบริการที่คุณเคยสั่งซื้อไว้กับเราได้อีกต่อไป
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" my={2}>
        ข้อกำหนดด้านสินค้าและบริการ
      </Typography>
      <Typography component="p">
        เราจัดจำหน่ายสินค้าและบริการที่สัมพันธ์กับการส่งเสริมทักษะด้านการเขียนโปรแกรม
        เพื่อป้องกันความเสียหายทางธุรกิจเราจึงกำหนดข้อตกลงการใช้งานดังต่อไปนี้
        <ul>
          <li>
            ผู้ใช้บริการพึงระลึกอยู่เสมอว่าสินค้าและบริการของเราเป็นงานอันมีลิขสิทธิ์
            ห้ามมิให้ผู้ใดเปิดเผย เผยแพร่ สำเนา ส่งต่อ ทำซ้ำ ใช้เป็นต้นแบบ
            หรือใช้งานสินค้าและบริการของเราร่วมกับผู้อื่นที่ไม่มีสิทธิ์
            เว้นแต่จะได้รับอนุญาตเป็นลายลักษณ์อักษรจากเรา
            หากเราพบการกระทำดังกล่าว เราจะดำเนินการทางกฏหมายตามสมควร
          </li>
          <li>
            สินค้าและบริการบางรายการมีกำหนดอายุ
            ผู้ใช้บริการต้องศึกษารายละเอียดของผลิตภัณฑ์ให้ดีก่อนการสั่งซื้อ
          </li>
          <li>
            ในการสั่งซื้อสินค้าและบริการของเรา โปรดชำระค่าลงทะเบียนให้ครบถ้วน
            หากคุณดำเนินการชำระน้อยกว่าราคาผลิตภัณฑ์คุณจะไม่สามารถเข้าถึงสินค้าและบริการที่คุณสั่งซื้อได้โดยเราจะถือเสมือนว่าคุณมิเคยทำการสั่งซื้อผลิตภัณฑ์นั้น
            ๆ มาก่อน อย่างไรก็ตามหากคุณชำระส่วนต่างครบถ้วนภายใน 3 วัน
            เราจะดำเนินการส่งมอบผลิตภัณฑ์ให้แก่คุณโดยเร็ว
          </li>
          <li>
            ค่าธรรมเนียมอันเกิดจากการสั่งซื้อสินค้าและบริการ เช่น
            ค่าธรรมเนียมการโอนเงินของธนาคาร
            ถือเป็นความรับผิดชอบของผู้สั่งซื้อสินค้าและบริการ
          </li>
          <li>
            การส่งมอบหลักฐานการชำระเงินเป็นหน้าที่ของผู้ซื้อสินค้าและบริการ
            หากผู้สั่งซื้อได้ดำเนินการชำระเงินแล้วแต่ไม่ส่งมอบหลักฐานการชำระเงินให้กับเรา
            เราไม่สามารถทราบได้ว่าผู้ใดคือลูกค้าของเราเป็นผลให้เราไม่สามารถส่งมอบสินค้าและบริการให้ได้
            เราขอสงวนสิทธิ์ไม่รับผิดชอบใด ๆ
            อันเกิดจากการที่ลูกค้าไม่ส่งมอบหลักฐานการชำระเงินนั้น ๆ
          </li>
          <li>
            สินค้าประเภทที่สามารถเข้าใช้งานได้ผ่านเว็บไซต์ www.codesass.com เช่น
            คอร์สออนไลน์
            จะสามารถเข้าใช้งานได้จนกว่าจะสิ้นสุดเวลาตามที่กำหนดในแต่ละผลิตภัณฑ์
            หากเว็บไซต์ www.codesass.com หยุดให้บริการ สินค้าใด ๆ
            ที่อาศัยการใช้งานผ่านเว็บไซต์จะสิ้นสุดอายุการใช้งานทันทีเช่นกัน
          </li>
          <li>
            ผู้ใช้งานพึงพิจารณาอย่างถี่ถ้วนโดยอ่านข้อมูลผลิตภัณฑ์ก่อนการสั่งซื้อทุกครั้ง
            เมื่อคุณได้ทำการลงทะเบียนเพื่อสั่งซื้อสินค้าและบริการแล้วเราจะไม่สามารถคืนค่าลงทะเบียนหรือเปลี่ยนแปลงการสั่งซื้อ
            เช่น เปลี่ยนเป็นผลิตภัณฑ์อื่นให้กับคุณได้ในทุกกรณี
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" my={2}>
        ข้อกำหนดด้านลิขสิทธิ์
      </Typography>
      <Typography component="p">
        ผลิตภัณฑ์ต่าง ๆ ของเราได้รับการคุ้มครองตามกฎหมายลิขสิทธิ์
        เราจึงกำหนดข้อตกลงสำหรับการเข้าถึงและใช้งานผลิตภัณฑ์ของเรา เช่น
        คอร์สออนไลน์ เพื่อป้องกันปัญหาอันอาจเกิดขึ้นได้จากการละเมิดลิขสิทธิ์
        <ul>
          <li>
            ไม่อนุญาตให้คัดลอก ทำซ้ำ ส่งต่อ ดัดแปลง แชร์เนื้อหา
            ข้อมูลหรือส่วนอื่นใดจากสินค้าและบริการนี้ให้บุคคลอื่น
            หรืออนุญาตให้บุคคลอื่นเข้าใช้บัญชีผู้ใช้งานเพื่อเข้าถึงเนื้อหา
            ข้อมูลหรือส่วนอื่นใดจากสินค้าและบริการนี้ หากพบการกระทำดังกล่าว
            กรณีที่สินค้าและบริการนั้นอยู่บนเว็บไซต์ของเรา เช่น คอร์สออนไลน์
            ผู้ดูแลสามารถถอนสิทธิ์การเข้าถึงสินค้าและบริการนั้น ๆ
            ได้โดยไม่ต้องคืนเงิน
            กรณีที่การกระทำดังกล่าวส่งผลต่อเว็บไซต์โดยตรงเราสามารถดำเนินคดีตามกฏหมายได้ตามสมควร
          </li>
        </ul>
      </Typography>
      <Typography component="p" my={2} fontWeight="bold">
        ประกาศ ณ วันที่ 8 สิงหาคม 2565
      </Typography>
    </Box>
  );
};

export default Policy;
